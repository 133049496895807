const MODE = process.env.mode;

var tempAc = {};

MODE == "development"
  ? (tempAc = {
      urlPrefix: process.env.baseUrlDevelopment,
      apiUrlPrefix: process.env.baseUrlDevelopment + "api/",
      boldRedDelimiter: "~",
      imageAltText: "Unable to load image",
      tollFreeNumber: "+91886102999",
      email: "mail@sentientdevelopers.com",
    })
  : (tempAc = {
      urlPrefix: process.env.baseUrlProduction,
      apiUrlPrefix: process.env.baseUrlProduction + "api/",
      boldRedDelimiter: "~",
      imageAltText: "Unable to load image",
      tollFreeNumber: "+91886102999",
      email: "mail@sentientdevelopers.com",
    });
const AppConfig = tempAc;

export default AppConfig;
