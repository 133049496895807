import Head from "next/head";
import { withRouter } from "next/router";
import React from "react";
import AppConfig from "../../AppConfig";

const Header = ({ router, keywords, description, title, canonicalUrl }) => {
  return (
    <React.Fragment>
      <Head>
        <link
          rel="preconnect"
          href="https://sentientdevelopers.com/"
          crossOrigin="true"
        />
        <link rel="preconnect" href="https://google.com" crossOrigin="true" />
        <link
          rel="preconnect"
          href="https://www.googletagmanager.com"
          crossOrigin="true"
        />
        <link
          rel="preconnect"
          href="https://www.google-analytics.com"
          crossOrigin="true"
        />
        <link
          rel="canonical"
          href={
            canonicalUrl
              ? canonicalUrl
              : AppConfig.urlPrefix + router.asPath.substring(1)
          }
        />
        <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
        <meta httpEquiv="cache-control" content="Private" />
        <meta httpEquiv="Expires" content="31536000" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        {/* <meta name="theme-color" content="#0FCA56" /> */}
        <meta
          name="theme-color"
          media="(prefers-color-scheme: light)"
          content="#0FCA56"
        />
        <meta
          name="theme-color"
          media="(prefers-color-scheme: dark)"
          content="#0FCA56"
        />
        <meta
          name="Description"
          content={
            description
              ? description
              : "Sentient Developers was born to provide sustainable solutions to the biggest problems and challenges of real estate and construction and strives to design and develop self-sustaining societal ecosystems on the principles of nature, which will show the way for the future of humanity."
          }
        />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta
          name="keywords"
          content={
            keywords
              ? keywords
              : "sentient,developers,beegru,real,estate,advisory,pwa,next,property,bangalore,bengaluru,home,house,apartment,land,plot,rent,buy,sell,agriculture,farm,construction,architect,architecture,interior,design,material,ui,township,react"
          }
        />
        <meta name="robots" content="index, follow" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content={title ? title : "Sentient Developers"} />
        <meta property="og:title" content={title ? title : "Sentient Developers"} />
        <meta
          property="og:description"
          content={
            description
              ? description
              : "Sentient Developers was born to provide sustainable solutions to the biggest problems and challenges of real estate and construction and strives to design and develop self-sustaining societal ecosystems on the principles of nature, which will show the way for the future of humanity."
          }
        />
        <meta property="og:url" content="https://sentientdevelopers.com/" />
        <meta
          property="og:image"
          content="https://sentientdevelopers.com/images/icons/logo/og_512.jpg"
        />
        <meta
          property="og:image:alt"
          content="https://sentientdevelopers.com/images/icons/logo/og_512.jpg"
        />
        <meta
          property="og:image:secure_url"
          content="https://sentientdevelopers.com/images/icons/logo/og_512.jpg"
        />
        <meta property="og:image:type" content="image/jpeg" />
        <meta property="og:image:width" content="512" />
        <meta property="og:image:height" content="512" />
        <meta property="og:locale" content="en_GB" />
        <link rel="icon" href="/images/icons/logo/favicon_48.png" />
        <link
          rel="shortcut icon"
          type="image/png"
          href="/images/icons/logo/favicon_48.png"
        />
        <link
          rel="apple-touch-icon"
          href="/images/icons/logo/favicon_192.png"
        />
        <link rel="manifest" href="/manifest.json" />
        <title>{title ? title : "Sentient Developers"}</title>
      </Head>
    </React.Fragment>
  );
};

export default withRouter(Header);
